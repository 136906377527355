import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/hero'
import Boilerplate from '../../components/boilerplate'

const IndexPage = () => (
  <Layout lang="en">
    <SEO
      title="Data protection"
      keywords={['creopharm', 'pharma', 'co-packing']}
    />

    <Hero lang="en" />

    <div className="container medium-padding">
      <h2>Data protection</h2>

      <p>
        Below, we would like to provide you with an overview of how we collect
        and process your personal data as part of our working relationship with
        you and what rights you have based on the data protection regulation.
        You will also find information as to what data we collect in connection
        with our business partnership with you and how such data are processed.
        The information that we provide is founded on Articles 13, 14 of the EU
        General Data Protection Regulation (GDPR).
      </p>
      <p>
        <strong>
          1. Controller for the collection and processing of your data
        </strong>
      </p>

      <p>
        Creopharm GmbH
        <br />
        Bielefelder Str. 21a
        <br />
        49124 Georgsmarienhütte / Germany
        <br />
        Phone: +49 5401 8814-0
        <br />
        Fax: +49 5401 8814-100
        <br />
        Email:{' '}
        <a href="mailto:datenschutz@creopharm.de">datenschutz@creopharm.de</a>
        <br />
        Website: <a href="http://www.creopharm.de/">www.creopharm.de</a>
      </p>

      <p>
        <strong>2. Data protection officer</strong>
      </p>

      <p>
        Creodis GmbH has engaged a data protection officer. You can contact our
        corporate data protection officer by e-mail at:
        <a href="mailto:datenschutz@creopharm.de">datenschutz@creopharm.de</a>
        or by post at the address shown above.
      </p>

      <p>
        <strong>3. What data does Creopharm GmbH use?</strong>
      </p>

      <p>
        Creopharm GmbH processes personal data concerning customers and their
        points of contact which it receives in the course of the business
        relationship or in the initiation of such business relationships.
      </p>

      <p>
        Moreover, we process personal data which we derive from public records
        in compliance with data protection provisions, e.g. commercial
        registers, debtor lists, the Internet, or which are made available to us
        by credit agencies.
      </p>

      <p>
        This includes the following data categories: personal master data (name,
        occupation, industry, trade name, date of birth); contact data (address,
        phone number, e-mail), payment data for cashless payments (bank data, EC
        card data), legitimation data (login files, identity card data,
        signatures), business history data (offers, order confirmations,
        turnover, delivery addresses), data on financial performance (credit
        information) and advertising data (e.g. consent to or refusals of
        advertising).
      </p>

      <p>
        <strong>
          4. For what purposes does Creopharm GmbH process your data and on what
          legal grounds?
        </strong>
      </p>

      <p>
        Creopharm GmbH processes your personal data in compliance with the
        requirements of the EU General Data Protection Regulation (GDPR) as well
        as national regulations in order to achieve the following purposes:
      </p>

      <p>
        We process personal data in order to fulfil contracts, including the
        handling of cashless payments, the processing of complaints and, where
        required, for claims settlements. Moreover, we process your data prior
        to concluding contracts if, and to the extent, that you request our
        products and services. The processing of such data is undertaken on the
        basis of point (b) of Article 6(1) GDPR.
      </p>

      <p>
        We furthermore process your data if you furnish your consent for us to
        do so. In such cases, the processing is done on the basis of point (a)
        of Article 6(1) GDPR. When obtaining your consent, we will inform you of
        the specific purpose of the envisaged processing. Any consent provided
        to us can be withdrawn by you at any time with future effect. This
        equally applies to any consent, which you may have provided prior to 25
        May 2018.
      </p>

      <p>
        Finally, we process data if, and to the extent, that we have a
        legitimate interest, except where such interest is overridden by your
        interests or fundamental rights which require protection of personal
        data. In such a case, the processing is undertaken on the basis of point
        (f) of Art. 6(1)(1) GDPR. The processing includes the dispatch of
        information and offers from Creopharm GmbH, inquiries as to your
        satisfaction, and market and opinion research, provided that you do not
        object to such processing. Moreover, we process personal data, on the
        basis of our legitimate interest, in order to evaluate the
        creditworthiness of a customer in order to assert our payment claims,
        including the engagement of collection and legal services.
      </p>

      <p>
        <strong>5. To whom does Creopharm GmbH forward your data?</strong>
      </p>

      <p>
        Within the Creopharm GmbH organisation, your data is provided to those
        departments which require such data in order to fulfil a contract or
        conduct the pre-contractual steps (e.g. financial accounting). In
        addition. departments are sent your data if there is a legitimate
        interest for them to process such data, e.g. the Marketing department,
        in order to contact you with advertising.
      </p>

      <p>
        To the extent that it is required, we will also forward data to
        supporting service providers (e.g. postal services, financial services
        providers, logistics companies, IT service providers, collection
        services and legal services).
      </p>

      <p>
        Our company routinely checks the creditworthiness of our business
        partners at the time of concluding agreements as well as in certain
        other instances in which there is justified interest in doing so. For
        this purpose, we work with Creditreform Osnabrück/ Nordhorn Unger KG
        (Parkstrasse 32, 49080 Osnabrück, Germany), which supplies us with the
        requisite data. To this end, we forward to Creditreform the names of our
        business partners along with the pertinent contact details. For further
        information on how data are processed, please consult the detailed
        Creditreform information leaflet that has been compiled in compliance
        with Art. 14 EU GDPR.
      </p>
      <p>
        Outside Creopharm GmbH, your data are forwarded to third parties: to the
        tax authorities in fulfilment of obligations to report and to prove the
        accuracy of tax returns, and also to law enforcement agencies to the
        extent that a legal obligation to do so exists.
      </p>

      <p>
        <strong>
          6. Does Creopharm GmbH transfer your data to an international
          organisation or a third country?
        </strong>
      </p>

      <p>
        Our website uses Google Analytics and Google Remarketing. These services
        are provided by Google Inc. (Google).
      </p>

      <p>
        You can prevent Google Analytics and Google Remarketing from collecting
        your data by clicking on one of the links below. This will install an
        opt-out cookie on your computer so that none of your data are recorded
        in the future when you visit this website:
        <a href="http://tools.google.com/dlpage/gaoptout?hl=de">
          tools.google.com/dlpage/gaoptout?hl=de
        </a>
        .
      </p>

      <p>
        Google Analytics and Google Remarketing are services provided by Google.
        Google uses so-called cookies, i.e. text files, that are saved on your
        computer. The cookie analyses your use of this website. It enables
        information on your use of our website to be collected. Information is
        generated by the cookie about your use of our website which (along with
        your IP address) is transmitted to a Google server in the USA and stored
        there. The “anonymizelP” function for IP addresses ensures, within the
        European Union or member states of the European Economic Area, that the
        IP address is truncated by the last octet so that it can no longer be
        clearly associated with your IP address. Google complies with the data
        protection regulations of the US Safe Harbor Agreement and is registered
        with the Safe Harbor programme of the US Department of Commerce. Google
        uses the information to evaluate your use of the website. Website
        activity reports are compiled for the website operator and further
        services provided in connection with your website and Internet use. The
        information may be forwarded to third parties by Google if this is
        prescribed by law or third parties process the data on Google’s behalf.
        Advertisements are posted by third-party suppliers and Google on
        websites on the Internet. Google and third-party suppliers will use the
        data to post advertising on third-party websites.
      </p>

      <p>
        Under no circumstances will Google associate the IP address with other
        data. You may object, at any time, with future effect, to the collection
        and storing of data. You can disable the use and saving of cookies by
        Google by making the necessary changes in your browser software settings
        by calling up
        <a href="http://www.google.de/privacy/ads">
          www.google.de/privacy/ads
        </a>{' '}
        and clicking on the opt-out button.
      </p>

      <p>
        Alternatively, you can disable the use of cookies by third-party
        providers by going to the opt-out page of the Network Advertising
        Initiative at{' '}
        <a href="http://www.networkadvertising.org/managing/opt_out.asp">
          www.Networkadvertising.org/managing/opt_out.asp.{' '}
        </a>
        Further information on Google’s privacy notice can be found at{' '}
        <a href="http://www.google.com/intl/de/privacy">
          www.google.com/intl/de/privacy
        </a>
        .
      </p>

      <p>
        Should you object to the use of cookies, please note that you may not be
        able to use all of the functions on this website. (Extract: privacy
        statement Google Analytics).
      </p>

      <p>
        This website also uses Google Conversion Tracking. Through this feature,
        Google AdWords places a cookie on your computer should you visit our
        website via a Google advertisement. These cookies lose their validity
        after 30 days and do not serve to identify you personally. If a user
        visits certain pages of a website operated by an AdWords customer and
        the cookie has not yet expired, Google and the customer can detect that
        the user has clicked on the advertisement and was directed to this site.
        Each AdWords customer is given a different cookie. Cookies cannot
        therefore be tracked via the websites of AdWords customers.
      </p>

      <p>
        If you do not wish to participate in the tracking process, you can also
        refuse the necessary placement of cookies – for example, by going to
        your browser settings and generally disabling the placement of cookies.
        You can also disable cookies for conversion tracking by setting your
        browser so that it blocks cookies from the domain{' '}
        <a href="http://www.googleadservice.com/">www.googleadservice.com</a>.{' '}
        More information on Google’s privacy notice concerning conversion
        tracking can be found at:
      </p>

      <p>
        <a href="http://support.google.com/adwords/answer/1722022">
          Support.google.com/adwords/answer/1722022
        </a>
      </p>

      <p>
        <strong>Incorporation of Google Maps</strong>
      </p>

      <p>
        Our website incorporates Google Maps. As soon as you access the page on
        which Google Maps is incorporated, information concerning your access is
        transmitted to Google. In this process, Google will receive your IP
        address, the identifier relating to the web browser you are using as
        well as details concerning which page was used to access the feature.
      </p>

      <p>
        Further information is available from Google at:{' '}
        <a href="http://www.google.de/intl/de/policies/">
          www.google.de/intl/de/policies/
        </a>
      </p>

      <p>
        <strong>7. For how long are your data stored by Creopharm GmbH?</strong>
      </p>

      <p>
        We store your personal data for as long as needed in order to fulfil the
        contract with you. If, and to the extent, that your data are subject to
        specific retention periods under tax, commercial or any other law, we
        will save such data until the retention periods in question have
        expired. These retention periods are, for example, 6 years under the
        corresponding tax regulations, and 10 years under commercial law.
      </p>

      <p>
        <strong>
          8. Information on customer data protection rights / Supervisory
          authority
        </strong>
      </p>

      <p>
        Every customer has the right to access the data on them that are stored
        at Creodis GmbH as well as the purpose of their processing in accordance
        with Art. 15 GDPR, the right to obtain rectification of incorrect or
        incomplete personal data in acc. with Art. 16 GDPR, the right to obtain
        the erasure of personal data in acc. with Art. 17 GDPR upon contract
        having been fulfilled, provided this does not conflict with any legal
        obligations to preserve the data, the right to restriction of processing
        in acc. with Art. 18 GDPR, the right to object in acc. with Art. 21
        GDPR, and the right to data portability in acc. with Art. 20 GDPR.
      </p>

      <p>
        Should you have consented to Creodis GmbH processing your personal data,
        you can withdraw such consent at any time without affecting the
        lawfulness of the processing that was undertaken on the basis of the
        originally issued consent prior to it being withdrawn.
      </p>

      <p>
        For questions or information on how your personal data are protected and
        how to establish the above rights, please contact our data protection
        officer via the contact details provided.
      </p>

      <p>
        <strong>
          9. Information on the existence of a right to object pursuant to Art.
          21 GDPR
        </strong>
      </p>

      <p>
        You have the right, if there are grounds for your particular situation,
        to object to your data being processed if the processing is necessary
        for the performance of a task carried out in the public interest or in
        the exercise of an official authority (point (e) of Art. 6(1)(1) GDPR)
        vested in Creodis GmbH. The equally applies for profiling based on the
        aforementioned provision. Should you object to the processing, we will
        cease processing your data for these purposes unless we can demonstrate
        compelling legitimate grounds for the processing which override your
        interests, rights and freedoms or the processing serves the
        establishment, exercise or defence of legal claims.
      </p>

      <p>
        We process your data in order to contact you with advertising and, in
        this context, carry out the processing of data in order to align our
        marketing activities to your interests (profiling). The processing of
        data for direct advertising occurs on the basis of an overriding
        legitimate interest of Creopharm GmbH.
      </p>

      <p>
        You can object, at any time, to your personal data being processed for
        direct advertising. This equally applies in the case of profiling to the
        extent that the data are used to contact you with advertising. Should
        you object to the processing for the purpose of direct advertising, your
        data will no longer be processed for such purposes.
      </p>

      <p>
        Your objection does not need to comply with any particular form and can
        be submitted to:
      </p>

      <p>
        Creopharm GmbH, Bielefelder Str.21a, 49124 Georgsmarienhütte, Phone
        05401 8814-0, Email:{' '}
        <a href="mailto:datenschutz@creopharm.de">datenschutz@creopharm.de</a>
      </p>

      <p>
        <strong>
          10. Do customers and their points of contact need to provide Creodis
          GmbH with personal data?
        </strong>
      </p>

      <p>
        To initiate a contract, conclude a contract and ultimately to fulfil a
        contract with Creodis GmbH, it is necessary for the customer data
        specified Point to be collected and processed. Without such data, we are
        not in a position to enter into a business relationship with customers
        or provide any contractually agreed services in the course of an
        existing business relationship.
      </p>

      <p>Valid as of 01.01.2019</p>
    </div>

    <Boilerplate lang="en" />
  </Layout>
)

export default IndexPage
